export var menu = [
	{
		title: 'ANALYTICS',
		path: '/dashboard',
		children: [
			{
				icon: 'uil-battery-bolt',
				title: 'HOME',
				path: '/dashboard',
			},
			{
				icon: 'uil-chart-pie',
				title: 'RFM_ANALYTIC',
				path: '/analytics/rfm',
			},
			// {
			// 	icon: 'uil-analytics',
			// 	title: 'LOYALTY_INDICATOR',
			// 	path: '/analytics/loyalty',
			// },
			{
				icon: 'uil-arrow-growth',
				title: 'USERS_ANALYTIC',
				path: '/users-analytic',
			},
			{
				icon: 'uil-receipt',
				title: 'FINANCE',
				path: '/update',
			},
			{
				icon: 'uil-users-alt',
				title: 'USERS',
				path: '/update',
			},
			{
				icon: 'uil-store',
				title: 'PRODUCTS_SERVICES',
				path: '/update',
			},
			{
				icon: 'uil-exchange',
				title: 'OPERATIONS',
				path: '/update',
			},
		],
	},
	{
		title: 'REPORTS',
		children: [

			{
				icon: 'uil-receipt',
				title: 'FINANCE',
				path: '/update',
			},
			{
				icon: 'uil-users-alt',
				title: 'CLIENTS',
				path: '/users-report',
			},
			{
				icon: 'uil-receipt',
				title: 'PAYMENT_HISTORY',
				path: '/payment-history',
			},
			{
				icon: 'uil-exchange',
				title: 'OPERATIONS',
				path: '/update',
			},
			{
				icon: 'uil-receipt-alt',
				title: 'CHEQUES',
				path: '/cheques',
			},
			{
				icon: 'uil-store',
				title: 'PRODUCTS_SERVICES',
				path: '/update',
			},
		],
	},
	{
		title: 'PROGRAMS',
		children: [
			{
				icon: 'uil-star',
				title: 'FAVORITE_USERS',
				path: '/favorite-users',
			},
			{
				icon: 'uil-raindrops',
				title: 'FUNDED_PROGRAM_BY_POINTS',
				path: '/services-by-points',
			},
			{
				icon: 'uil-money-insert',
				title: 'FUNDED_PROGRAM_BY_AMOUNT',
				path: '/services-by-amount',
			},
			{
				icon: 'uil-create-dashboard',
				title: 'FUNDED_PROGRAM_BY_CATEGORY',
				path: '/services-by-category',
			},
			{
				icon: 'uil-shopping-basket',
				title: 'FUNDED_PROGRAM_BY_PRODUCT',
				path: '/services-by-product',
			},
			{
				icon: 'uil-mars',
				title: 'FUNDED_PROGRAM_BY_GENDER',
				path: '/services-by-gender',
			},
			{
				icon: 'uil-sun',
				title: 'FUNDED_PROGRAM_BY_DAY',
				path: '/services-by-day',
			},
			{
				icon: 'uil-clock',
				title: 'FUNDED_PROGRAM_BY_HOUR',
				path: '/services-by-hour',
			},
			{
				icon: 'uil-calendar-alt',
				title: 'FUNDED_PROGRAM_BY_CALENDAR_DAY',
				path: '/services-by-calendar-day',
			},
		],
	},
	{
		title: 'SETTINGS',
		children: [
			{
				icon: 'uil-suitcase',
				title: 'BUSINESSES',
				path: '/pos',
			},
			{
				icon: 'uil-user-square',
				title: 'CASHIERS',
				path: '/cashiers',
			},
			{
				icon: 'uil-shopping-cart',
				title: 'CATEGORY',
				path: '/categories',
			},
			{
				icon: 'uil-shopping-cart',
				title: 'PRODUCTS_SERVICES',
				path: '/products',
			},
			{
				icon: 'uil-cog',
				title: 'RFM_SETTINGS',
				path: '/rfm/settings',
			},
			{
				icon: 'uil-cog',
				title: 'POS_GROUP_SETTINGS',
				path: '/owner-settings',
			},
			{
				icon: 'uil-cog',
				title: 'MESSAGE_TEMPLATES',
				path: '/message-templates',
			},
			{
				icon: 'uil-clipboard-notes',
				title: 'BLACK_LIST',
				path: '/black-list',
			},
		],
	},
]