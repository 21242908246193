import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { DELETE, GET, POST, PUT } from 'helpers/api'
import { formatDate, formatDateWithTime, regexPhoneNumber } from "helpers/helpers"
import ConfirmModal from "pages/parts/ConfirmModal"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [actions, setActions] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showConfirmModal, setShowConfirmModal] = useState({ show: false, id: '' })

	const [showModal, setShowModal] = useState(false)
	const [showActionsModal, setActionsShowModal] = useState(false)

	async function createUpdate() {
		var selectedItemCopy = { ...selectedItem }
		var response;
		selectedItemCopy.clientLogin = '998' + selectedItemCopy.clientLogin
		if (selectedItemCopy.id) {
			response = await PUT('/services/gocashweb/api/users-blacklist', selectedItemCopy, { loader: true })
		} else {

			response = await POST('/services/gocashweb/api/users-blacklist', selectedItemCopy, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	async function changeStatus(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			id: dataCopy[index]['id'],
			activated: e.target.checked,
		}
		await PUT(`/services/web/api/users-blacklist/activate`, sendData, { loader: true })
	}

	async function deleteItem() {
		const response = await DELETE(`/services/gocashweb/api/users-blacklist`, { id: showConfirmModal.id, }, { loader: true })
		if (response) {
			setShowConfirmModal({ show: false, id: '' })
			getData()
		}
	}

	// function openModal(item) {
	// 	var itemCopy = { ...item }
	// 	setSelectedItem(itemCopy)
	// 	setShowModal(true)
	// }

	async function openActionsModal(item) {
		const response = await GET(`/services/gocashweb/api/users-blacklist/${item.id}/actions`)
		setActions(response)
		setActionsShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function getData() {
		const response = await GET('/services/gocashweb/api/users-blacklist/pageList', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('BLACK_LIST')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('PHONE')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {regexPhoneNumber(item.clientLogin)}</td>
										<td className="text-center">{formatDate(item.createdDateTime)}</td>
										<td className="text-center">
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => changeStatus(e, index)} />
												</div>
											</div>
										</td>
										<td className="d-flex justify-content-center gap-2">
											{/* <div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div> */}
											<div className="table-action-button table-action-danger-button" onClick={() => setShowConfirmModal({ ...showConfirmModal, show: true, id: item.id })}>
												<i className="uil-trash-alt"></i>
											</div>

											<div className="table-action-button table-action-primary-button" onClick={() => openActionsModal(item)}>
												<i className="uil-ellipsis-v"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => createUpdate()} autoComplete="off">
						<div className="form-group">
							<label>{t('PHONE')}<span className="required-mark">*</span></label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									value={selectedItem.clientLogin}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'clientLogin': e.target.value })} />
							</div>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL */}
			<Modal show={showActionsModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setActionsShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('ACTION')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<tr>
								<th>ID</th>
								<th className="text-end">{t('DATE')}</th>
							</tr>
						</thead>
						<tbody>
							{actions.map((item, index) => (
								<tr key={index}>
									<td>{item.id}</td>
									<td className="text-end">{formatDateWithTime(item.actionDateTime)}</td>
								</tr>
							))
							}
						</tbody>
					</table>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<ConfirmModal modalConfirm={showConfirmModal.show} setModalConfirm={setShowConfirmModal} confrim={deleteItem} />
		</>
	)
}

export default Index
