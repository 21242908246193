import React from "react"

// Dashboard
import DashboardIndex from "../pages/dashboard/Index"

// Analytics
import RfmIndex from "pages/analytics/Rfm/Index"
import RfmSettings from "pages/analytics/Rfm/Settings"
import RfmSettingsCreateUpdate from "pages/analytics/Rfm/CreateUpdate"
import Loyalty from "pages/analytics/Loyalty"

import Users from "../pages/report/Users"
import PaymentHistory from "../pages/report/UsersPaymentHistory"
import UsersAnalytic from "../pages/report/UsersAnalytic"

import PosGroupIndex from "../pages/posGroup/Index"
import PosGroupCreateUpdate from "../pages/posGroup/CreateUpdate"

import OwnerSettings from "pages/owner_settings/Index"

import PosIndex from "../pages/pos/Index"
import PosCreateUpdate from "../pages/pos/CreateUpdate"

import Cheques from "../pages/cheques/Index"

import MessageTemplates from "../pages/message_templates/Index"

import BlackList from "../pages/black_list/Index"

import Products from "../pages/products/Index"
import ProductsCreateUpdate from "../pages/products/CreateUpdate"

import Categories from "../pages/categories/Index"

// 
import ServicesByPoints from "../pages/services/services_by_points/Index"
import ServicesByPointsCreateUpdate from "../pages/services/services_by_points/CreateUpdate"

import ServicesByAmount from "../pages/services/services_by_amount/Index"
import ServicesByAmountCreateUpdate from "../pages/services/services_by_amount/CreateUpdate"

import ServicesByCategories from "../pages/services/services_by_categories/Index"
import ServicesByCategoriesCreateUpdate from "../pages/services/services_by_categories/CreateUpdate"

import ServicesByProducts from "../pages/services/services_by_products/Index"
import ServicesByProductsCreateUpdate from "../pages/services/services_by_products/CreateUpdate"

import ServicesByGender from "../pages/services/services_by_gender/Index"
import ServicesByGenderCreateUpdate from "../pages/services/services_by_gender/CreateUpdate"

import ServicesByDay from "../pages/services/services_by_day/Index"
import ServicesByDayCreateUpdate from "../pages/services/services_by_day/CreateUpdate"

import ServicesByHour from "../pages/services/services_by_hour/Index"
import ServicesByHourCreateUpdate from "../pages/services/services_by_hour/CreateUpdate"

import ServicesByCalendarDay from "../pages/services/services_by_calendar_day/Index"
import ServicesByCalendarDayCreateUpdate from "../pages/services/services_by_calendar_day/CreateUpdate"

// 

import Clients from "../pages/clients/Index"
import ClientDetail from "../pages/clients/Detail"
import Purchases from "../pages/purchases/Index"
import Cashiers from "../pages/cashiers/Index"
import FavoriteUsers from "../pages/favorite-users/Index"

// Authentication related pages
import Login from "../pages/authentication/Login"
import ForgotPassword from "../pages/authentication/ForgotPassword"
import MobileLogin from "pages/mobile/Login"

import Update from "components/Update"

const userRoutes = [
	{ path: "/dashboard", component: <DashboardIndex /> },

	// Analytics
	{ path: "/analytics/rfm", component: <RfmIndex /> },
	{ path: "/rfm/settings", component: <RfmSettings /> },
	{ path: "/rfm/settings/create", component: <RfmSettingsCreateUpdate /> },
	{ path: "/rfm/settings/edit/:id", component: <RfmSettingsCreateUpdate /> },
	{ path: "/analytics/loyalty", component: <Loyalty /> },

	// Report
	{ path: "/users-report", component: <Users /> },
	{ path: "/users-analytic", component: <UsersAnalytic /> },
	{ path: "/payment-history", component: <PaymentHistory /> },

	{ path: "/pos-group", component: <PosGroupIndex /> },
	{ path: "/pos-group-create", component: <PosGroupCreateUpdate /> },
	{ path: "/pos-group/:id", component: <PosGroupCreateUpdate /> },

	{ path: "/pos-group/poses/:id", component: <PosIndex /> },
	{ path: "/pos-group/pos/create/:posGroudId", component: <PosCreateUpdate /> },
	{ path: "/pos-group/pos/:posGroudId/:id", component: <PosCreateUpdate /> },

	{ path: "/owner-settings", component: <OwnerSettings /> },

	{ path: "/pos", component: <PosIndex /> },
	{ path: "/pos/create/:posGroudId", component: <PosCreateUpdate /> },
	{ path: "/pos/update/:id", component: <PosCreateUpdate /> },

	{ path: "/products", component: <Products /> },
	{ path: "/products-create", component: <ProductsCreateUpdate /> },
	{ path: "/products-update/:id", component: <ProductsCreateUpdate /> },

	{ path: "/categories", component: <Categories /> },

	{ path: "/cheques", component: <Cheques /> },

	{ path: "/message-templates", component: <MessageTemplates /> },

	{ path: "/black-list", component: <BlackList /> },

	{ path: "/services-by-points", component: <ServicesByPoints /> },
	{ path: "/services-by-points/create", component: <ServicesByPointsCreateUpdate /> },
	{ path: "/services-by-points/:id", component: <ServicesByPointsCreateUpdate /> },

	{ path: "/services-by-amount", component: <ServicesByAmount /> },
	{ path: "/services-by-amount/create", component: <ServicesByAmountCreateUpdate /> },
	{ path: "/services-by-amount/:id", component: <ServicesByAmountCreateUpdate /> },

	{ path: "/services-by-category", component: <ServicesByCategories /> },
	{ path: "/services-by-category/create", component: <ServicesByCategoriesCreateUpdate /> },
	{ path: "/services-by-category/:id", component: <ServicesByCategoriesCreateUpdate /> },

	{ path: "/services-by-product", component: <ServicesByProducts /> },
	{ path: "/services-by-product/create", component: <ServicesByProductsCreateUpdate /> },
	{ path: "/services-by-product/:id", component: <ServicesByProductsCreateUpdate /> },

	{ path: "/services-by-gender", component: <ServicesByGender /> },
	{ path: "/services-by-gender/create", component: <ServicesByGenderCreateUpdate /> },
	{ path: "/services-by-gender/:id", component: <ServicesByGenderCreateUpdate /> },

	{ path: "/services-by-day", component: <ServicesByDay /> },
	{ path: "/services-by-day/create", component: <ServicesByDayCreateUpdate /> },
	{ path: "/services-by-day/:id", component: <ServicesByDayCreateUpdate /> },

	{ path: "/services-by-hour", component: <ServicesByHour /> },
	{ path: "/services-by-hour/create", component: <ServicesByHourCreateUpdate /> },
	{ path: "/services-by-hour/:id", component: <ServicesByHourCreateUpdate /> },

	{ path: "/services-by-calendar-day", component: <ServicesByCalendarDay /> },
	{ path: "/services-by-calendar-day/create", component: <ServicesByCalendarDayCreateUpdate /> },
	{ path: "/services-by-calendar-day/:id", component: <ServicesByCalendarDayCreateUpdate /> },

	// 

	{ path: "/clients", component: <Clients /> },
	{ path: "/clients/:id", component: <ClientDetail /> },
	{ path: "/purchases", component: <Purchases /> },

	{ path: "/cashiers", component: <Cashiers /> },

	{ path: "/favorite-users", component: <FavoriteUsers /> },

	{ path: "/update", component: <Update /> },
]

const authRoutes = [
	{ path: "/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgotPassword /> },

	{ path: "/mobile/login", component: <MobileLogin /> },
]

export { userRoutes, authRoutes }